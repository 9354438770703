<template>
  <v-container min-height="100" class="px-3 shop-reg-form">
    <v-stepper v-model="curr" color="green" class="pb-6 pt-8 px-4">
      <p class="primary--text font-weight-bold ma-0">店舗登録</p>
      <v-stepper-content
        class="pb-6 pt-5 px-0"
        v-for="(step, n) in steps"
        :step="n + 1"
        :key="n"
      >
        <validation-observer :ref="'observer'">
          <v-form
            :ref="'stepForm'"
            v-model="step.valid"
            lazy-validation
            autocomplete="off"
          >
            <!-- Step 1 -->
            <v-card-text class="">
              <v-row v-for="field in step.fields" :key="field.name">
                <template v-if="field.type == 'text'">
                  <v-col
                    cols="12"
                    :md="field.additional_field ? '6' : '12'"
                    class="px-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="field.rules"
                      :name="field.name"
                    >
                      <v-label
                        >{{ field.label }}
                        <span
                          v-if="field.rules.includes('required')"
                          class="required-text"
                        >
                          必須
                        </span>
                      </v-label>
                      <v-text-field
                        :placeholder="field.placeholder"
                        solo
                        background-color="#EAEDF0"
                        v-model="field.value"
                        :error-messages="errors"
                        :hide-details="errors.length <= 0"
                        @blur="field.name == 'email' ? register(n) : ''"
                        autocomplete="chrome-off"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </template>
                <template v-if="field.type == 'datepicker'">
                  <v-col
                    cols="12"
                    :md="field.additional_field ? '6' : '12'"
                    class="px-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="field.rules"
                      :name="field.name"
                    >
                      <v-label
                        >{{ field.label }}
                        <span
                          v-if="field.rules.includes('required')"
                          class="required-text"
                        >
                          必須
                        </span>
                      </v-label>
                      <DatePicker
                        solo
                        background-color="#EAEDF0"
                        :hide-details="errors.length <= 0"
                        v-model="field.value"
                        :separator="'-'"
                        :field="field"
                        :errors="errors"
                      ></DatePicker>
                      <div class="v-messages theme--light error--text">
                      {{ errors ? errors[0] : "" }}
                      </div>
                    </validation-provider>
                  </v-col>
                </template>
                <template v-if="field.type == 'dropdown'">
                  <v-col
                    cols="12"
                    :md="field.additional_field ? '6' : '12'"
                    class="px-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="field.rules"
                      :name="field.name"
                    >
                      <v-label
                        >{{ field.label }}
                        <span
                          v-if="field.rules.includes('required')"
                          class="required-text"
                        >
                          必須
                        </span>
                      </v-label>
                      <v-select
                        :label="field.placeholder"
                        solo
                        :items="field.items"
                        :item-text="field.item_text"
                        :item-value="field.item_value"
                        v-model="field.value"
                        :error-messages="errors"
                        :hide-details="errors.length <= 0"
                        background-color="#EAEDF0"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </template>
              </v-row>
              <v-row>
                <v-col class="px-0 pt-0 pb-0" cols="12" md="12">
                  <v-btn
                    block
                    v-if="n + 1 <= lastStep"
                    color="primary"
                    class="stepper-btns mt-2"
                    @click="register(n, 'true')"
                    :loading="loading"
                    :disabled="!step.valid"
                    >次へ進む</v-btn
                  >
                  <v-btn
                    v-else
                    class="stepper-btns mt-2"
                    block
                    color="primary"
                    @click="register(n, 'true')"
                    :loading="loading"
                    :disabled="loading || !step.valid"
                    >登録完了</v-btn
                  >
                  <v-btn
                    block
                    class="mt-3"
                    color="stepper-btns grey white--text"
                    v-if="curr != 1"
                    @click="curr = n"
                    >戻る</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </validation-observer>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import DatePicker from "@/components/ui/DatePicker";
import { mapGetters } from "vuex";
export default {
  name: "ShopRegForm",
  components: { DatePicker },
  data() {
    return {
      loading: false,
      errors: "",
      formStatus: false,
      curr: 1,
      lastStep: 1,
      steps: [
        {
          name: "Step 1",
          valid: true,
          fields: [
            {
              label: "店舗名",
              name: "store_name",
              type: "text",
              placeholder: "redishレストラン",
              value: "",
              rules: "required:店舗名"
            },
            {
              label: "郵便番号",
              name: "postal_code",
              type: "text",
              placeholder: "000-0000",
              value: "",
              rules: "required:郵便番号"
            },
            {
              label: "都道府県",
              name: "prefectures",
              type: "dropdown",
              items: [],
              item_text: "name",
              item_value: "id",
              placeholder: "東京都",
              value: "",
              rules: ""
            },
            {
              label: "市区町村・番地",
              name: "city_address",
              type: "text",
              placeholder: "市区町村・番地",
              value: "",
              rules: ""
            },
            {
              label: "建物名・部屋番号等",
              name: "building_name_room_number",
              type: "text",
              placeholder: "〇〇ビル 〇階",
              value: "",
              rules: ""
            },
            {
              label: "メールアドレス",
              name: "mail_address",
              type: "text",
              placeholder: "",
              value: "",
              rules: "email"
            },
            {
              label: "電話番号",
              name: "phone_number",
              type: "text",
              placeholder: "000-0000-0000",
              value: "",
              rules: "required:電話番号|enter_half_width_numbers_hyphens"
            },
            {
              label: "代表者",
              name: "representative",
              type: "text",
              placeholder: "山田 太郎",
              value: "",
              rules: ""
            }
          ]
        },
        {
          name: "Step 2",
          valid: true,
          fields: [
            {
              label: "会社形態",
              name: "company_type",
              type: "dropdown",
              items: this.$store.getters.getMasterData?.company_type,
              item_value: "id",
              item_text: "value",
              placeholder: "個人事業主",
              value: "",
              rules: ""
            },
            {
              label: "登記日",
              name: "registration_date",
              type: "datepicker",
              placeholder: "2021/01",
              value: "",
              rules: "required:登記日",
              locale: "ja",
              pickerType: "month",
              date_format: "YYYY-MM",
              date: "",
              menu: false
            },
            {
              label: "事業年度の開始月",
              name: "start_of_fiscal_year",
              type: "dropdown",
              placeholder: "1月",
              rules: "required:事業年度の開始月",
              items: [
                { id: "1", value: "1月" },
                { id: "2", value: "2月" },
                { id: "3", value: "3月" },
                { id: "4", value: "4月" },
                { id: "5", value: "5月" },
                { id: "6", value: "6月" },
                { id: "7", value: "7月" },
                { id: "8", value: "8月" },
                { id: "9", value: "9月" },
                { id: "10", value: "10月" },
                { id: "11", value: "11月" },
                { id: "12", value: "12月" }
              ],
              item_value: "id",
              item_text: "value",
              value: ""
            },
            {
              label: "従業員数",
              name: "number_of_employees",
              type: "dropdown",
              items: this.$store.getters.getMasterData?.number_of_employees,
              item_value: "id",
              item_text: "value",
              placeholder: "2〜5名",
              value: "",
              rules: "numeric"
            }
          ]
        }
      ],
      valid: false,
      stepForm: [],
      observer: []
    };
  },
  created() {
    this.getDataFromApi();
    this.disableRouterBackwad();
  },
  computed: {
    ...mapGetters(["getPrefectures"])
  },
  methods: {
    disableRouterBackwad() {
      if (this.$store.getters.getFirstShopObject.id == 0) {
        history.pushState(null, null, document.URL);
        window.addEventListener("popstate", function() {
          history.pushState(null, null, document.URL);
        });
        this.$route.meta.isWithoutHeader = true;
      } else if (this.$store.getters.getFirstShopObject.id != 0) {
        this.$route.meta.isWithoutHeader = false;
      }
    },
    getDataFromApi() {
      this.loading = true;
      this.$store.dispatch("PREFECTURES_GET_ALL").finally(() => {
        this.loading = false;
        this.steps[0].fields.find(
          i => i.name == "prefectures"
        ).items = this.getPrefectures;
      });
    },
    stepComplete(step) {
      return this.curr > step;
    },
    moveToNextStep(n) {
      this.steps[n].valid = false;
      this.$refs.observer[n].validate().then(success => {
        if (!success) {
          return;
        }
        this.steps[n].valid = true;
        // continue to next
        this.curr = n + 2;
      });
    },

    register(currentStep = "false", clicked = "false") {
      this.loading = false;
      let data = new FormData();
      let isFilledFeilds = true;
      this.steps.forEach(step => {
        step.fields.forEach(field => {
          if (field) {
            if ({}.hasOwnProperty.call(field, "additional_field")) {
              data.append(
                field.additional_field.name,
                field.additional_field.value
              );
            }
            if (field.value) {
              data.append(field.name, field.value);
            }
            if (field.value == "") {
              isFilledFeilds = false;
            }
          }
        });
      });

      if (currentStep != this.lastStep && isFilledFeilds) {
        this.moveToNextStep(currentStep);
        this.loading = false;
        return;
      }

      if (currentStep == 1) {
        this.$refs.observer[currentStep].validate().then(success => {
          if (!success) {
            return;
          }
          this.loading = true;
          this.$store
            .dispatch("SHOP_REGISTER", data)
            .then(response => {
              if (response.status === 200) {
                this.$store.dispatch("SHOP_GET_ALL");
                this.$store.commit("FIRST_SHOP_OBJECT", {
                  shop: response.data.data.shop
                });
                this.$router.push({ name: "managedStores" });
                this.loading = false;
              }
            })
            .catch(error => {
              if (error?.data?.errors)
                this.$refs.observer[currentStep].setErrors(error?.data?.errors);
            })
            .finally(() => (this.loading = false));
        });
      } else {
        if (currentStep != "false") {
          this.moveToNextStep(currentStep);
          this.loading = false;
        } else {
          this.curr = 1;
          this.loading = false;
          this.steps.forEach((value, key) => {
            this.$refs.observer[key].setErrors();
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
