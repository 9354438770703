<template>
  <v-container min-height="100" class="px-5 mt-10 add-store">
    <p class="text-center mx-auto text-primary font-weight-bold">
      店舗アカウントと紐づけ<br />
      日報を管理しましょう。
    </p>
    <div class="mt-16 mb-8">
      <p class="mb-2">
        既存の店舗アカウントを利用したい方
      </p>

      <p class="text-caption text--secondary">
        店舗アカウント管理者からの招待メールをお待ちください。メールが届きましたら、記載のURLからログインしてください。
      </p>
    </div>

    <div>
      <p class="">新しく店舗アカウントを作成したい方</p>
      <v-btn
        block
        dense
        class="font-14px font-Roboto font-weight-bold py-2 primary white--text"
        :to="{ name: 'ShopRegForm' }"
      >
        店舗アカウントを作成
      </v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "AddStore",
  created() {
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
        history.pushState(null, null, document.URL);
    });
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
